.ft-card {
    border-radius: 2px;
    position: relative;
    background-size: cover;
    height: 350px;
    padding: 20px;
    cursor: pointer;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
    transition: all $transition;
    transition-property: transform, box-shadow, opacity;
    overflow: hidden;
    font-size: $base-font-size;

    //modifiers

    &.-disabled {
        cursor: default;
        pointer-events: none;
    }

    &.-inactive {
        &:before {
            content: '';
            @include overlay;
            z-index: 10;
            background: rgba($white, 0.5);
        }
    }

    &.-overlay {
        &:not(.-disabled):hover:after {
            opacity: 0.8;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(135deg, rgba($black, 0.5) 30%, rgba($black, 0.1) 100%);
            transition: opacity .2s linear;
        }
    }

    &:not(.-disabled):hover {
        transform: translate(0, -5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, .3)
    }

    &.-large {
        height: 570px;
        padding: 30px;
        font-size: 20px;

        &.-dynamic-height {
            min-height: 570px;
            height: auto;
        }
    }

    &.-medium {
        height: 300px;
        padding: 18px;
        font-size: 16px;
    }

    &.-small {
        height: 275px;
        padding: 15px;
        font-size: 14px;
    }

    &_content {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
}

.ft-card-placeholder {
    background: $gray-100;
    border: 1px dashed $gray-500;
    height: 350px;
    padding: 20px;
}


.ft-experience-card {
    background-position: center;
    background-color: #1e4566;
    color: $white;
    line-height: 1.2;
    display: flex;
    align-items: flex-end;
    padding: 0 !important;

    &_content {
        height: auto;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, .6) 66%);
        padding: 2em 20px 20px;

        .ft-card.-large &{
            padding: 15em 30px 30px;
        }
    }

    &_title {
        line-height: 1.2;
        font-weight: normal;
    }

    &_dates-wrap {
        display: inline-block;
        margin-top: 1em;
        font-size: 11px;
        font-weight: bold;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;

        &, * {
            color: #fff;
        }
    }

    &_dates {
        display: inline-block;
        padding: 0.5em 1em;
        border-radius: 2em;
        background-color: transparent;
        border-color: #fff;
        border-width: 1px;
        border-style: solid;
    }
}

.ft-card-bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures it behaves like a background */
}
